<template>
  <div class="card card-body shadow-sm infomations-list">
    <kyy-app-modal-student-order @handleUpdateSuccess="handleUpdateSuccess" :endPoint="endPointEdit" ref="modal_list_order"></kyy-app-modal-student-order>
    <kyy-app-modal-create-student-order @handleUpdateSuccess="handleUpdateSuccess" ref="modal_create_order"></kyy-app-modal-create-student-order>
    <app-modal-confirm
      ref="order_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteSelectedOrder()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-basic-table
      ref="basic_table"
      :table-name="$t('restaurant.list_title')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.KYY_STUDENT_ORDER_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <!-- Search by order code -->
          <div class="col-xl-3 col-lg-12 col-sm-12">
              <div class="form-group">
                <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.order_code.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                  <app-select :name="filters.order_code.name"
                    input-style="normal"
                    v-model="filters.order_code.value"
                    :options-data="meta.customers"
                  />
                </div>
            </div>
          </div>

          <!-- Search by order date -->
          <div class="col-xl-3 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.order_date.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                <app-date-range-picker
                  :name="filters.order_date.name"
                  v-model="filters.order_date.value"
                  timePicker24Hour="false"
                  format="YYYY-MM-DD"
                  @input="filters.order_date.value = $event"
                />
              </div>
            </div>
          </div>

          <!-- Search by payment method -->
          <div class="col-xl-3 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.payment.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                <app-select :name="filters.payment.name"
                    input-style="normal"
                    v-model="filters.payment.value"
                    :options-data="STUDENT_ORDER_LIST_CONSTANT.STUDENT_PAYMENT_METHOD"
                  />
              </div>
            </div>
          </div>

           <!-- Search by status -->
           <div class="col-xl-3 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.paid_status.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                <app-select :name="filters.paid_status.name"
                    input-style="normal"
                    v-model="filters.paid_status.value"
                    :options-data="STUDENT_ORDER_LIST_CONSTANT.STUDENT_PAID_STATUS"
                  />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common_vn.create") }}
        </button>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none" style="max-width: max-content !important;">
          <div class="d-flex">
            <button
              @click="handleBtnDeleteClick(props.row.id)"
              class="btn btn-danger mr-2"
            >
              {{ $t("common_vn.delete") }}
            </button>
            <button
              @click="handleBtnDetailClick(props.row)"
              class="btn btn-primary"
            >
              {{ $t("common_vn.detail") }}
            </button>
          </div>
        </td>
      </template>

      <template v-slot:body-cell-image_path="props">
        <td class="app-align-middle" style="display: flex;justify-content: center;">
          <img :src="props.row.image_path" v-if="props.row.image_path" style="width: 100px; height: 100px">
        </td>
      </template>

      <template v-slot:body-cell-total_cost="props">
        <td class="app-align-middle text-center">
          {{ convertCurrency(props.row.total_cost, ) }}
        </td>
      </template>

      <template v-slot:body-cell-payment="props">
        <td class="app-align-middle text-center">
          {{ getLabelFromOptionArray(props.row.payment, STUDENT_ORDER_LIST_CONSTANT.STUDENT_PAYMENT_METHOD) }}
        </td>
      </template>

      <template v-slot:body-cell-serve_date="props">
        <td class="app-align-middle text-center" style="min-width: 180px;">
          <div v-for="(item, index) in props.row.student_order_items" :key="index"
            :style="index > 0 ? 'border-top: 1px solid #dee2e6' : ''"
          >
            {{ item.sell_date }}
          </div>
        </td>
      </template>
    </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {STUDENT_ORDER_LIST_CONSTANT} from "../../../constants/kyy_student_order_list";
  import {convertCurrency, getLabelFromOptionArray} from "@utils";
  import KyyAppModalStudentOrder from "@components/_common/popup/KyyAppModalStudentOrder";
  import KyyAppModalCreateStudentOrder from '@components/_common/popup/KyyAppModalCreateStudentOrder.vue';

  export default {
    name: "RestaurantList",
    components: {
      "app-basic-table": AppBasicTable,
      "kyy-app-modal-student-order": KyyAppModalStudentOrder,
      "kyy-app-modal-create-student-order": KyyAppModalCreateStudentOrder,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          order_code: {
            name: "order_code",
            condition: "equal",
            label: this.$t("student_order.order_code")
          },
          order_date: {
            name: "order_date",
            condition: "equal",
            label: this.$t("student_order.order_date")
          },
          paid_status: {
            name: "paid_status",
            condition: "equal",
            label: this.$t("student_order.paid_status")
          },
          payment: {
            name: "payment",
            condition: "equal",
            label: this.$t("student_order.payment")
          },
        },
        columns: [
          {name: "order_code", label: this.$t("student_order.order_code"), textAlign: 'text-center'},
          {name: "order_date", label: this.$t("student_order.order_date"), sortable: true, textAlign: 'text-center'},
          {name: "item_quantity", label: this.$t("student_order.item_quantity"), textAlign: 'text-right'},
          {name: "total_cost", label: this.$t("student_order.price"), textAlign: 'text-right'},
          {name: "serve_date", label: this.$t("student_order.serve_date"), textAlign: 'text-center'},
          {name: "payment", label: this.$t("student_order.payment"), sortable: true, textAlign: 'text-center'},
          {name: "edit", label: this.$t("common_vn.action"), textAlign: 'text-center'},
        ],
        meta: [],
        endPointEdit: '',
        deleteId: '',
        customerList: [],
      };
    },
    watch : {
      "filters.from_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
      "filters.to_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
    },
    methods: {
      async updateStatus(e, id) {
        await this.$request.get(this.ENDPOINT.KYY_STUDENT_ORDER_EDIT(id)).then(res => {
          if (res.hasErrors()) {
            return ;
          }
          this.entry = res.data.data
        })

        if (this.entry.status != e) {
          let res = null;
          let msg = "";
          res = await this.$request.patch(this.ENDPOINT.KYY_STUDENT_ORDER_EDIT(id), {
            status : e
          });
          msg = this.$t("common.msg update ok")
          if (res.hasErrors()) {
            this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.__noticeSuccess(msg);
          }
        }
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            // 'filters.order_code.equal' : 'all',
            // 'filters.order_date.equal' : 'all'
          }
        })
      },
      getOrderSize(sizeID) {
        let type = STUDENT_ORDER_LIST_CONSTANT.ITEM_SIZE.find(({id}) => id === sizeID);
        if (type == undefined) {
          return '';
        }
        return type.name;
      },
      onSearch() {
        // this.getTourSelectName();
      },

      // handle open creat order
      handleBtnCreateClick() {
        this.$refs.modal_create_order.show();
      },

      // handle open edit order
      handleBtnDetailClick(entry) {
        this.endPointEdit = this.ENDPOINT.KYY_STUDENT_ORDER_EDIT(entry.id)
        this.$refs.modal_list_order.show(this.endPointEdit);
      },

      handleUpdateSuccess() {
        this.$refs.basic_table.getEntries();
      },

      handleBtnDeleteClick(id) {
        this.deleteId = id
        this.$refs.order_modal_delete.show();
      },

      async deleteSelectedOrder() {
        const res = await this.$request.delete(this.ENDPOINT.KYY_STUDENT_ORDER_DELETE(this.deleteId));
        if (!res.hasErrors()) {
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          this.$refs.basic_table.getEntries();
          this.$refs.order_modal_delete.hide();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.news_modal_delete.hide();
        }
      }
    },
    mounted: function () {
      this.$request.get(this.ENDPOINT.UI_HELPER_KYY_STUDENT_ORDER).then(res => {
        let customers = _.cloneDeep(res.data.customers);
        customers = customers.map((customer) => {
          return {
            id: customer?.user_name,
            name: customer?.user_name
          }
        })
        customers.unshift({
          id: 'all',
          name: '',
        });
        this.meta = {
          customers: customers,
        }
      })
    },
  }
</script>
